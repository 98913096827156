import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const manifestUrl = `${process.env.REACT_APP_BASE_URL}tonconnect-manifest.json`;

const container = document.getElementById('root');

// Для пререндеринга используйте hydrateRoot
ReactDOM.hydrateRoot(
  container,
  <React.StrictMode>
    <Provider store={store}>
      <TonConnectUIProvider manifestUrl={manifestUrl}>
        <App />
      </TonConnectUIProvider>
    </Provider>
  </React.StrictMode>
);

//serviceWorkerRegistration.register();
