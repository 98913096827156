import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tasks: [],
    balance: 0,
    ton_balance: 0.0,
    energy: 1500,
    maxEnergy: 1500,
    currentLeague: 'Bronze',
    level: 1,
    damageAmount: 1,
    multiplier: 1,
    serverTime: '',
    username: '',
    profileImg: '',
    dailyTaskCompleted: false,
    incomePerHour: 0,
    turboEndTime: 0,
    energyRecoveryLevel: 1,
    dailyFullEnergyCount: 0,
    dailyTurboCount: 0,
    miningBotStatus: 0,
    cardsPurchased: [false, false, false],
    dailyTappedCoins: 0,
    referralCode: '',
    friends: [],
    referralLink: '',
    addTaskUrl: '',
    leagues: [],
    players: {},
    combo_reward: 0,
    leagueThresholds: {
        Bronze: 0,
        Silver: 1000000,
        Gold: 25000000,
        Platinum: 400000000,
        Diamond: 6400000000,
    },
    levelThresholds: {
        1: 1,
        2: 25000,
        3: 100000,
        4: 500000,
        5: 1000000,
        6: 2500000,
        7: 5000000,
        8: 12500000,
        9: 25000000,
        10: 50000000,
        11: 100000000,
        12: 200000000,
        13: 400000000,
        14: 800000000,
        15: 1600000000,
        16: 3200000000,
        17: 6400000000,
        18: 12800000000,
        19: 25600000000,
        20: 51200000000,
    },
    spentCoin: 0,
    lastEnergyUpdate: '',
    rewards: [100000, 250000, 500000, 1000000, 2000000, 4000000, 8000000, 16000000, 32000000, 64000000, 128000000, 256000000],
    dailyRewardDay: 1, // Добавляем dailyRewardDay
    loadedImages: {},
    lastLoginDate: '', // Добавляем lastLoginDate
    cards: [],
    earnedCoins: 0,
    tappedCoins: 0,
    viewsToday: 0,
    lastViewTime: '',
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.balance = action.payload.balance;
            state.ton_balance = action.payload.ton_balance;
            state.combo_reward = action.payload.combo_reward;
            state.energy = action.payload.current_energy;
            state.maxEnergy = action.payload.max_energy;
            state.currentLeague = action.payload.league;
            state.level = action.payload.level;
            state.damageAmount = action.payload.damage_amount;
            state.multiplier = action.payload.multiplier;
            state.referralCode = action.payload.referral_code;
            state.incomePerHour = action.payload.income_per_hour;
            state.energyRecoveryLevel = action.payload.energy_recovery_level;
            state.dailyFullEnergyCount = action.payload.daily_full_energy_count;
            state.dailyTurboCount = action.payload.daily_turbo_count;
            state.miningBotStatus = action.payload.mining_bot_status;
            state.spentCoin = action.payload.spent_coin;
            state.tappedCoins = action.payload.tappedCoins;
            state.dailyTappedCoins = action.payload.dailyTappedCoins;
            state.dailyTaskCompleted = action.payload.dailyTaskCompleted;
            state.earnedCoins = action.payload.earnedCoins;
            state.dailyRewardDay = action.payload.daily_reward_day; // Добавляем в setUserData
            state.lastLoginDate = action.payload.last_login_date; // Добавляем в setUserData
            state.tasks = action.payload.tasks || [];
            state.cards = action.payload.cards || [];
            state.cardsPurchased = action.payload.cards_purchased || [false, false, false];
            state.viewsToday = action.payload.views_today || 0; // Устанавливаем количество просмотров рекламы
            state.lastViewTime = action.payload.last_view_time || '';
            state.serverTime = action.payload.server_time || '';
            state.addTaskUrl = action.payload.add_task_url || '';
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
        setTonBalance: (state, action) => {
            // Добавляем новый редюсер для ton_balance
            state.ton_balance = action.payload;
        },
        setComboReward: (state, action) => {
            // Добавляем новый редюсер для ton_balance
            state.combo_reward = action.payload;
        },
        setCards: (state, action) => {
            state.cards = action.payload; // Редюсер для установки массива карточек
        },
        setIncomePerHour: (state, action) => {
            state.incomePerHour = action.payload; // Редюсер для установки массива карточек
        },
        setEnergy: (state, action) => {
            state.energy = action.payload;
        },
        setMaxEnergy: (state, action) => {
            state.maxEnergy = action.payload;
        },
        setCurrentLeague: (state, action) => {
            state.currentLeague = action.payload;
        },
        setLevel: (state, action) => {
            state.level = action.payload;
        },
        setSpentCoin: (state, action) => {
            state.spentCoin = action.payload;
        },
        setTappedCoins: (state, action) => {
            state.tappedCoins = action.payload;
        },
        setServerTime: (state, action) => {
            state.serverTime = action.payload;
        },
        setEarnedCoins: (state, action) => {
            state.earnedCoins = action.payload;
        },
        setDamageAmount: (state, action) => {
            state.damageAmount = action.payload;
        },
        setDailyTaskCompleted: (state, action) => {
            state.dailyTaskCompleted = action.payload; // Обновляем состояние выполнения задания
        },
        setMultiplier: (state, action) => {
            state.multiplier = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setProfileImg: (state, action) => {
            state.profileImg = action.payload;
        },
        setTurboEndTime: (state, action) => {
            state.turboEndTime = action.payload;
        },
        setDailyFullEnergyCount: (state, action) => {
            state.dailyFullEnergyCount = action.payload;
        },
        setDailyTurboCount: (state, action) => {
            state.dailyTurboCount = action.payload;
        },
        setMiningBotStatus: (state, action) => {
            state.miningBotStatus = action.payload;
        },
        setFriends: (state, action) => {
            state.friends = action.payload;
        },
        setReferralLink: (state, action) => {
            state.referralLink = action.payload;
        },
        setLeagues: (state, action) => {
            state.leagues = action.payload;
        },
        setPlayers: (state, action) => {
            state.players = action.payload;
        },
        setEnergyRecoveryLevel: (state, action) => {
            state.energyRecoveryLevel = action.payload;
        },
        setLeagueThresholds: (state, action) => {
            state.leagueThresholds = action.payload;
        },
        setLastEnergyUpdate: (state, action) => {
            state.lastEnergyUpdate = action.payload;
        },
        setDailyRewardDay: (state, action) => {
            state.dailyRewardDay = action.payload;
        },
        setLastLoginDate: (state, action) => {
            state.lastLoginDate = action.payload;
        },
        setLoadedImages: (state, action) => {
            state.loadedImages = action.payload;
        },
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setAddTaskUrl: (state, action) => {
            state.addTaskUrl = action.payload;
        },
        upgradeCard: (state, action) => {
            const { cardId, newLevel } = action.payload;
            const cardIndex = state.cards.findIndex((card) => card.id === cardId);
            if (cardIndex !== -1) {
                state.cards[cardIndex].level = newLevel;
            }
        },
        updateCardsPurchased: (state, action) => {
            const { cardId, index } = action.payload;
            // Обновляем карточку в массиве cardsPurchased по индексу
            if (index >= 0 && index < state.cardsPurchased.length) {
                state.cardsPurchased[index] = cardId;
            }
        },
        setViewsToday: (state, action) => {
            state.viewsToday = action.payload;
        },
        setLastViewTime: (state, action) => {
            state.lastViewTime = action.payload;
        },
        setDailyTappedCoins: (state, action) => {
            state.dailyTappedCoins = action.payload;
        },
        setDailyTappedCoins: (state, action) => {
            state.dailyTappedCoins = action.payload;
        },
        setCardsPurchased: (state, action) => {
            state.cardsPurchased = action.payload;
        },
    },
});

export const {
    setUserData,
    setBalance,
    setTonBalance,
    setEnergy,
    setCards,
    setIncomePerHour,
    setMaxEnergy,
    setCurrentLeague,
    setLevel,
    setAddTaskUrl,
    setSpentCoin,
    setEarnedCoins,
    setTappedCoins,
    setDamageAmount,
    setMultiplier,
    setUsername,
    setProfileImg,
    setTurboEndTime,
    setDailyFullEnergyCount,
    setDailyTurboCount,
    setMiningBotStatus,
    setFriends,
    setReferralLink,
    setLeagues,
    setPlayers,
    setDailyTaskCompleted,
    setEnergyRecoveryLevel,
    setLeagueThresholds,
    setLastEnergyUpdate,
    setDailyRewardDay,
    setLastLoginDate,
    setTasks,
    setCardsPurchased,
    setLoadedImages,
    upgradeCard,
    setComboReward,
    setViewsToday, // Новый редюсер для количества просмотров рекламы
    setLastViewTime,
    updateCardsPurchased,
    setDailyTappedCoins,
    setServerTime,
} = userSlice.actions;

export default userSlice.reducer;
