// BackgroundBlur.js
import React from 'react';

const BackgroundBlur = () => {
  return (
    <div className="background-blur" style={{ zIndex: 12 }}>
        <div className="blur-center">
            <svg width="740" height="326" viewBox="0 0 740 326" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_68_15)">
                    <ellipse cx="370" cy="-84" rx="70" ry="110" fill="#21C9E3" />
                </g>
                <defs>
                    <filter id="filter0_f_68_15" x="0" y="-494" width="740" height="820" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_68_15" />
                    </filter>
                </defs>
            </svg>
        </div>
      <svg className="background-blur2" width="375" height="400" viewBox="0 0 375 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1_11)">
          <ellipse cx="348.754" cy="366.389" rx="73.6571" ry="56.5366" transform="rotate(47.3736 348.754 366.389)" fill="#21C9E3" />
        </g>
        <defs>
          <filter id="filter0_f_1_11" x="-16.199" y="0.0279541" width="729.905" height="732.723" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_1_11" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default BackgroundBlur;
