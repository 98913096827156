// walletSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    walletAddress: '',
    walletStatus: 'disconnected', // 'connected' or 'disconnected'
};

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setWalletAddress: (state, action) => {
            state.walletAddress = action.payload;
        },
        setWalletStatus: (state, action) => {
            state.walletStatus = action.payload;
        },
    },
});

export const { setWalletAddress, setWalletStatus } = walletSlice.actions;

export default walletSlice.reducer;
