import React from 'react';
import './PCOverlay.css';

const PCOverlay = () => {
  return (
    <div>
        <div className="game_block">
            <h1>Play on your mobile</h1>
            <img src='/assets/qr_code.png' alt=''></img>
            <h2>@Cryptolamaz_bot</h2>
         </div>
    </div>
  );
};

export default PCOverlay;
